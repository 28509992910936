export default {
  category: {
    5: require('../images/category/computer.svg'),
    1: require('../images/category/kitchen.svg'),
    84: require('../images/category/spade.svg'),
    38: require('../images/category/airplane.svg'),
    15: require('../images/category/joystick.svg'),
    102: require('../images/category/mars.svg'),
  },

  logo: 'header/Logo_H2.svg',
};
