import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';

const Header = props => {
  const { title, textStyle } = props;
  return (
    <header>
      <div style={textStyle} className="container">
        <div style={textStyle} className="content">
          <p style={textStyle} className="title">Here Hear {title}</p>
          <a href="https://beta-api.herehear.com.tw/api/v2/payment">
            <p style={textStyle} className="buy">購買</p>
          </a>
        </div>
      </div>

      <style jsx>{`
        .container {
          height: 59px;
          border-bottom: 1px ${colors['white']} solid;
          display: flex;
          align-items: center;
          margin: auto;
        }

        .content {
          width: 100%;
          padding: 0 15px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        p {
          color: ${colors['white']};
          font-family: NotoSansCJKtc-Regular;
        }

        .title {
          font-size: 18px;
          line-height: 28px;
          display: flex;
          align-items: center;
        }

        .buy {
          font-size: 14px;
          border: 1px ${colors['white']} solid;
          border-radius: 5px;
          padding: 3px 18px;
          display: flex;
          align-items: center;
        }

        @media (min-width: 768px) {
          .container {
            width: 90%;
            height: 75px;
          }

          .content {
            padding: 0;
          }

          .title {
            font-size: 21px;
            line-height: 32px;
          }

          .buy {
            font-size: 21px;
            padding: 10px 29px;
          }
        }
        @media (min-width: 1200px) {
        }
      `}</style>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  textStyle: PropTypes.object,
};

Header.defaultProps = {
  title: '',
  textStyle: {},
};

export default Header;
