import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import 'lazysizes';
import colors from '../config/color';
import images from '../config/image';
import SearchBar from './searchBar';
import { Link } from 'gatsby';

const MenuButton = props => {
  const { name, slug } = props;
  return (
    <div className="menu-bg">
      <div className="menu-open">
        <div className="close-icon">
          <MdClose style={{ height: 30, width: 30 }} />
        </div>
        <div className="search-bar">
          <SearchBar />
        </div>
        <ul>
          <li className="category-content">
            <Link
              style={{ textDecoration: 'none' }}
              to={decodeURIComponent(slug)}
            >
              <div className="category-style">
                <img
                  alt={name}
                  style={{ width: 40, height: 40 }}
                  src={images.category[5]}
                />
                <h1 className="category-title">{'3C'}</h1>
              </div>
              <div className="category-style">
                <img
                  alt={name}
                  style={{ width: 40, height: 40 }}
                  src={images.category[1]}
                />
                <h1 className="category-title">{'家電'}</h1>
              </div>
              <div className="category-style">
                <img
                  alt={name}
                  style={{ width: 40, height: 40 }}
                  src={images.category[84]}
                />
                <h1 className="category-title">{'廚房用具'}</h1>
              </div>
              <div className="category-style">
                <img
                  alt={name}
                  style={{ width: 40, height: 40 }}
                  src={images.category[38]}
                />
                <h1 className="category-title">{'旅遊'}</h1>
              </div>
              <div className="category-style">
                <img
                  alt={name}
                  style={{ width: 40, height: 40 }}
                  src={images.category[15]}
                />
                <h1 className="category-title">{'遊戲'}</h1>
              </div>
            </Link>
          </li>
        </ul>
        <div className="menu-open-left-color"></div>
      </div>

      <style jsx>{`
        .menu-bg {
          position: relative;
        }

        .menu-open {
          z-index: 99;
          position: fixed;
          right: 0;
          top: 0;
          width: calc(85% - 40px);
          height: 100%;
          padding: 20px;
          background-color: ${colors['very-light-pink']};
          opacity: 0.95;
        }

        .close-icon {
          display: flex;
          justify-content: flex-end;
          color: ${colors['dirt-brown']};
        }

        .search-bar {
          margin-top: 20px;
          border: 1px solid ${colors['dirt-brown']};
          border-radius: 100px;
        }

        .category-content {
          display: flex;
          flex-direction: column;
          padding: 20px;
        }

        .category-style {
          width: 100%;
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid ${colors['dirt-brown']};
        }

        .category-title {
          color: ${colors['dirt-brown']};
          font-size: 16px;
          margin-left: 20px;
        }

        .menu-open-left-color {
          width: calc(15% + 40px);
          height: 100%;
          background-color: ${colors['dirt-brown']};
          opacity: 0.95;
          position: absolute;
          left: calc(-15% - 40px);
          top: 0;
        }

        @media (min-width: 579px) {
          .menu-icon {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

MenuButton.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
};

MenuButton.defaultProps = {
  id: 0,
  name: '',
  slug: '',
};

export default MenuButton;
