import React from 'react';
import colors from '../config/color';
import logoSvg from '../images/header/logo.svg';
import characterSvg from '../images/header/character.svg';
import circleSvg from '../images/header/circle.svg';

const Hero = () => {
  return (
    <div className="header-background">
      <div className="header-fixed">
        <a href={'/'} style={{ zIndex: 99 }}>
          <img alt="logo" className="logo" src={logoSvg} />
        </a>
        <div className="header-content">
          <img alt="hero-img" className="character" src={characterSvg} />
          <div className="slogan-content">
            <p className="quotation-mark-top">”</p>
            <p className="slogan">
              因文明推進產生的良品，等候此刻的不期而遇。
              <br />
              珍惜與它邂逅的機緣，
              <br />
              用文字留下生活新知，
              <br />
              為它的沈靜訴說故事，
              <br />
              <br />
              新時代的足跡陳列於好物櫥窗。
              <br />
            </p>
            <p className="quotation-mark-bottom">“ </p>
          </div>
        </div>
        <img
          alt="hero-back-img-1"
          className="circle circle-01 "
          src={circleSvg}
        />
        <img
          alt="hero-back-img-2"
          className="circle circle-02"
          src={circleSvg}
        />
        <img
          alt="hero-back-img-3"
          className="circle circle-03"
          src={circleSvg}
        />
      </div>

      <style jsx>{`
        .header-background {
          background-color: ${colors['very-light-pink']};
          padding: 20px;
          padding-bottom: 80px;
          position: relative;
          overflow: hidden;
          height: 420px;
        }

        .header-fixed {
          position: fixed;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        .circle {
          opacity: 0.03;
          width: 400px;
          height: 400px;
          position: absolute;
          top: -150px;
          right: -75px;
        }

        .circle-01 {
          -webkit-animation: circle 5s linear infinite;
          -moz-animation: circle 5s linear infinite;
          animation: circle 5s linear infinite;
        }
        .circle-02 {
          -webkit-animation: circle 6s linear infinite;
          -moz-animation: circle 6s linear infinite;
          animation: circle 6s linear infinite;
        }
        .circle-03 {
          -webkit-animation: circle 7s linear infinite;
          -moz-animation: circle 7s linear infinite;
          animation: circle 7s linear infinite;
        }

        @-moz-keyframes circle {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes circle {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes circle {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        .logo {
          width: 130px;
          z-index: 99;
        }

        .logo:hover {
          cursor: pointer;
        }

        .character {
          width: 280px;
          margin-top: 20px;
        }

        .header-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 99;
        }

        .slogan {
          font-size: 16px;
          margin-top: 20px;
          color: ${colors['dirt-brown']};
          line-height: 25px;
          font-weight: 300;
        }

        .slogan-content {
          height: 200px;
          position: relative;
        }

        .quotation-mark-top,
        .quotation-mark-bottom {
          font-size: 100px;
          color: #ffffff;
        }

        .quotation-mark-top {
          position: absolute;
          left: -10px;
          top: -30px;
        }

        .quotation-mark-bottom {
          position: absolute;
          right: -10px;
          bottom: -50px;
        }

        @media (min-width: 579px) {
          .header-content {
            flex-direction: row-reverse;
          }

          .slogan-content {
            margin: 60px 20px;
          }

          .circle {
            width: 500px;
            height: 450px;
            top: -180px;
            right: -70px;
          }

          .header-background {
            height: 320px;
          }

          .character {
            margin-left: 60px;
          }
        }

        @media (min-width: 768px) {
          .circle {
            width: 500px;
            height: 450px;
            top: -180px;
            right: -70px;
          }
        }

        @media (min-width: 924px) {
          .circle {
            width: 600px;
            height: 500px;
            top: -210px;
            right: -70px;
          }
        }

        @media (min-width: 1200px) {
          .circle {
            width: 700px;
            height: 700px;
            top: -400px;
            right: -70px;
          }
        }
      `}</style>
    </div>
  );
};

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
