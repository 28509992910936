/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import colors from '../config/color';
import Header from './header';
import './layout.css';
import { FaLine, FaFacebookSquare } from 'react-icons/fa';
import Hero from '../components/hero';
import MenuButton from '../components/menuButton';

const Layout = ({
  children,
  title,
  showHeader = false,
  showHero = false,
  breadcrumbs,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  console.log(
    // eslint-disable-next-line no-undef
    `${process.env.GATSBY_BUILD_NUMBER}.${process.env.GATSBY_BUILD_DATE}.${process.env.GATSBY_GIT_HASH}`,
  );

  return (
    <>
      {/* <MenuButton /> */}
      {showHeader ? (
        <Header
          siteTitle={title || data.site.siteMetadata.title}
          breadcrumbs={breadcrumbs}
        />
      ) : null}
      {showHero ? <Hero /> : null}
      <div className="main-content">
        <main>{children}</main>
      </div>

      {/* footer 樣式 */}
      {/* <footer>
        <p>COPYRIGHT © 2020 BAMBOO Technology LTD. ALL RIGHTS RESERVED.</p>
      </footer> */}

      <style jsx>{`
        footer {
          width: 100%;
          height: 50px;
          background-color: ${colors['slime-green']};
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .main-content {
          background-color: #fff;
          min-height: calc(100vh - 50px);
        }

        p {
          color: ${colors['black-p']};
          font-family: SFProDisplay-Regular;
          font-size: 9px;
          line-height: 14px;
          padding: 0 46px;
          letter-spacing: 0.05px;
        }

        @media (min-width: 768px) {
          footer {
            height: 110px;
          }

          p {
            font-size: 18px;
            padding: 0 60px;
            letter-spacing: 0.09px;
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.array,
};

Layout.defaultProps = {
  breadcrumbs: [],
};

export default Layout;
